/* Hide elements not meant for printing */


/* Print-specific styles */
@media print {
    .no-printing {
        display: none;
    }
    .show-tbl {
        display: block !important;
    }
    .break-after {
        page-break-after: always;
    }
    /* Set page margins and ensure elements fit within print area */
    @page {
        margin: 1cm;
    }

    .margin-up {
        margin-top: -60px;
    }

    .margin-up2 {
        margin-top: -45px;
    }

    .view-div{
        visibility: visible !important;
    }
}